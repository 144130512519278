<template>
	<div class="product-label-skus">
		<h4 class="mb-1">Attached product</h4>
		<p class="typo-body-2 color-black-45 mb-3">List of all SKUs that have been attached with this label.</p>
		<template v-if="skus.length">
			<div class="typo-body-2 color-black-70 mb-2">
				Total {{ skus.length }} SKU(s)
			</div>
			<div
				class="sku-list border border-gray-400 rounded p-3 mb-5"
			>
				<div
					v-for="item in skus"
					:key="item"
					class="typo-caption-2 d-inline-block mr-1"
				>
					{{ item }};
				</div>
			</div>
		</template>
		<BaseNoItemBanner
			v-else
			text="There is no sku attached."
		/>
	</div>
</template>

<script>
export default {
	name: 'ProductLabelSkuList',

	props: {
		skus: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		skuList() {
			return this.skus.join('; ');
		},
	},
};
</script>

<style lang="scss" scoped>
	.sku-list {
		max-height: rem(140);
		overflow-y: auto;
	}
</style>